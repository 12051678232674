.users-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.users-pagination {
  margin-top: 30px;
}

.users-pagination .MuiPaginationItem-text {
  font-weight: 700;
}

.users-pagination .Mui-selected {
  background-color: #008ec8 !important;
  color: white !important;
}

.user-dialog-form {
  width: 100%;
}

.user-dialog-form .text-input {
  margin-bottom: 14px;
  max-width: 250px;
}

.user-dialog-form .user-avatar {
  width: 300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.user-dialog-form h4 {
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 18px;
}

.user-dialog-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.user-total {
  width: 100%;
  background-color: #e7e7e7;
  padding: 10px 10px;
  font-size: 0.9rem;
  font-weight: 600 !important;
  font-family: Montserrat !important;
  border-radius: 4px;
}
