.articles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.articles-pagination {
  margin-top: 30px;
}

.articles-pagination .MuiPaginationItem-text {
  font-weight: 700;
}

.articles-pagination .Mui-selected {
  background-color: #008ec8 !important;
  color: white !important;
}

.article-dialog-form {
  width: 400px;
}

.article-dialog-form .text-input {
  margin-bottom: 14px;
}

.article-dialog-form .article-avatar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.article-dialog-form h4 {
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 18px;
}

.article-table-elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}
