.login-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.login-bg-image {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.login-bg-image #login-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.login-bg-image #logo {
  z-index: 4;
  position: absolute;
  right: 40px;
  opacity: 1;
  animation-name: logo-animation;
  animation-duration: 1.2s;
}

@keyframes logo-animation {
  0% {
    right: -400px;
    opacity: 0;
  }
  10% {
    right: -400px;
    opacity: 0;
  }
  100% {
    right: 40px;
    opacity: 1;
  }
}

.login-form-container {
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation-name: form-animation;
  animation-duration: 1.4s;
}

@keyframes form-animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-form-container h1 {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 5px;
  margin: 0px 15px;
}

.login-form-container p {
  font-size: 15px;
  font-weight: 400;
  color: #a1a1a8;
  margin-bottom: 30px;
}

.login-form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
}

.login-form-container .text-input {
  margin-bottom: 25px;
}

.login-form-container button {
  background-color: black;
  text-transform: none;
  padding: 8px 40px;
  font-size: 15px;
  font-weight: 400;
  margin: 25px 0px;
}

.login-form-container button:hover {
  background-color: rgb(20, 20, 20);
}

.login-form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.MuiFormControlLabel-label {
  color: #43425d !important;
  font-size: 14px !important;
}

.login-form-row a {
  color: #43425d;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .login-bg-image {
    display: none;
  }

  .login-form-container {
    width: 100%;
  }
}
