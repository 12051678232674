.article-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.article-container > div {
  display: flex;
  flex-direction: column;
}

.article-editor .ql-toolbar {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.article-editor .quill .ql-container {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.article-editor .article-quill-container:hover .ql-container {
  border: 1px solid #252525 !important;
  border-top: none !important;
}

.article-editor .quill:hover .ql-toolbar {
  border: 1px solid #252525 !important;
  border-bottom: 1px solid #0000003d !important;
}

.article-editor .quill:hover .ql-container {
  border: 1px solid #252525 !important;
  border-top: none !important;
}

.article-editor .quill:focus-within .ql-toolbar {
  border: 1px solid #1976d2 !important;
  border-bottom: 1px solid #0000003d !important;
  border-top: 1px solid white !important;
  box-shadow: 0 -1px 0 1px #1976d2 !important;
}

.article-editor .quill:focus-within .ql-container {
  border: 1px solid #1976d2 !important;
  border-top: none !important;
  box-shadow: 0 0px 0 1px #1976d2 !important;
}

.quill a {
  color: cornflowerblue;
  text-decoration: underline;
}

.quill a.pdf {
	display: inline-block;
	padding: 8px 12px;
	text-align: center;
	text-decoration: none !important;
	font-weight: 500;
	color: #ffffff;
	background-color: #1976d2;
	border-radius: 4px;
	outline: none;
}

.article-editor .ql-editor {
  padding: 16.5px 12px !important;
}

.article-editor .ql-editor p {
  /* Add drawer hide handler */
  max-width: calc(100vw - 48px - 278px - 240px - 24px - 22px);
  overflow-wrap: break-word;
}

.ql-editor iframe,
.ql-editor img {
  display: flex;
  margin: auto;
  max-width: 500px !important;
	object-fit: cover;
}
.ql-video {
  width: 500px;
  height: 280px;
}

.article-preview .ql-editor iframe,
.article-preview .ql-editor img {
  width: 100% !important;
  display: flex !important;
  margin: auto !important;
  min-width: 0px !important;
}
.article-preview .ql-video {
  width: 100% !important;
  height: 280px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.38) !important;
}

.ql-container {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
}

.ql-tooltip {
  z-index: 1201 !important;
}

.ql-toolbar {
  position: sticky;
  top: 70px;
  background-color: white;
  z-index: 1;
}

.add-content {
  color: cornflowerblue;
  text-decoration: underline;
}

.article-actions-container {
  padding: 14px;
  border: 1px solid #cacaca;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.article-actions-container h4 {
  margin: 0;
  margin-bottom: 6px;
  color: #595959;
  font-weight: 600;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
}

.article-actions-container button {
  text-transform: none !important;
  border-radius: 16px;
}

.notification-setting-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  opacity: 0;
}

.notification-setting-container h4 {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  color: #595959;
}

.notification-image-container {
  width: 400px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-image-container .edit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.notification-image-container .edit-btn input {
  display: none;
}

/* Quill fr translation */

.ql-tooltip[data-mode='link']::before {
  content: 'Lien hypertext' !important;
}
.ql-tooltip[data-mode='video']::before {
  content: 'Lien de la vidéo' !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Valider' !important;
}
