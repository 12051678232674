.dashboard-layout-root {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-width: 100vw;
  width: 100%;
}

.content {
}
