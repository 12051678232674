.passwd-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.passwd-form-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation-name: form-animation;
  animation-duration: 1.4s;
}

@keyframes form-animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.passwd-form-container h1 {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 3px;
  margin: 0px 15px;
}

.passwd-form-container p {
  font-size: 15px;
  font-weight: 400;
  color: #a1a1a8;
  margin-bottom: 30px;
}

.passwd-form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
}

.passwd-form-container .text-input {
  margin-bottom: 25px;
}

.passwd-form-container button {
  background-color: black;
  text-transform: none;
  padding: 8px 40px;
  font-size: 15px;
  font-weight: 400;
  margin: 25px 0px;
}

.passwd-form-container button:hover {
  background-color: rgb(20, 20, 20);
}

.passwd-form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.passwd-form-row a {
  color: #43425d;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
