.appbar {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.1) !important;
}

.toolbar {
  width: 100% !important;
  height: 70px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.appbar-title {
  font-size: 17px;
  font-weight: 600;
  font-family: Montserrat;
  margin: 0;
}

.drawer {
  background-color: #e7e7e7 !important;
  border: none !important;
}

.drawer-header {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.drawer-header img {
  width: 77%;
  margin-top: 6px;
}

.nav-item {
  color: black !important;
  font-weight: 600 !important;
  font-family: Montserrat !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.nav-item .MuiListItemText-primary {
  font-size: 0.9rem;
  font-weight: 600 !important;
  font-family: Montserrat !important;
}

.nav-item .MuiListItemIcon-root {
  color: black !important;
}

.nav-item-selected {
  background-color: #008ec8 !important;
  color: white !important;
}

.nav-item-selected .MuiListItemIcon-root {
  color: white !important;
}

.nav-item-footer {
  margin-top: auto !important;
  margin-bottom: 20px !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.app-bar-avatar-container {
  display: flex;
  align-items: center;
}

.app-bar-avatar-container h3 {
  margin-right: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
