.settings-container {
  width: 100%;
}

.row1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

img.rainbow {
  margin-left: 10px;
  width: 30px;
}

div.color-view {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.office-name-input {
  width: 240px;
  padding: 6px 6px;
  background: transparent;
  border: none;
  font-size: 15px;
  margin-right: 10px;
}

.office-name-input:focus {
  outline: none;
}

.delete-btn {
  opacity: 0;
}

.office-box:hover .delete-btn {
  opacity: 1;
}

.table-delete-btn {
  margin-left: 4px !important;
  opacity: 0;
}

.table-header-cell:hover .table-delete-btn {
  opacity: 1;
}

.table-body-cell:hover .table-delete-btn {
  opacity: 1;
}
